import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { AccessLevel } from 'src/app/core/enums/access-level.enum';
import {
    ExcelFileFormatErrorMessage,
    RequiredFieldsErrorMessage,
    RequiredFieldsErrorTitle,
} from '../../../../core/constants/validator-constants';
import { ModuleName } from '../../../../core/enums/module-name.enum';
import { Dropdown } from '../../../../core/models/dropdown/dropdown';
import { AggregatorsOptionsService } from '../../../../core/services/api/aggregators-options.service';
import { UITemplateService } from '../../../../core/services/api/ui-template.service';
import { AllowedExcelFileMimeType } from '../../../../core/services/constant';
import { ErrorService } from '../../../../core/services/error.service';
import { StorageService } from '../../../../core/services/storage.service';
import { PopupService } from '../popup.service';

@Component({
    selector: 'popup-import',
    templateUrl: './popup-import.component.html',
    styleUrls: ['./popup-import.component.scss'],
})
export class PopupImportComponent implements OnInit {
    form: FormGroup;
    showFormError = false;
    isLoading = false;

    title: string = 'Import';
    companyGroupFieldTitle: string;
    fieldTitle: string = 'Select a dropdown below before import.';
    fieldTitle2: string;
    moduleName: ModuleName = null;
    companyGroupList: Dropdown[] = [];
    companyList: Dropdown[] = [];
    dropdownList2: Dropdown[] = [];
    isCompanyAdmin = false;
    isCompanyGroupAdmin = false;
    isServiceProvider = false;

    isInitDropdown: boolean = true;
    isCompanyGroupDisabled: boolean = false;
    isCompanyDisabled: boolean = false;

    constructor(
        private activeModal: NgbActiveModal,
        private aggregatorsOptionsService: AggregatorsOptionsService,
        private errorService: ErrorService,
        private formBuilder: FormBuilder,
        private popupService: PopupService,
        private storageService: StorageService,
        private UITemplateService: UITemplateService,
    ) {}

    ngOnInit(): void {
        this.isCompanyAdmin = this.storageService.accessLevel == AccessLevel.Company;
        this.isCompanyGroupAdmin = this.storageService.accessLevel == AccessLevel.CompanyGroup;
        this.isServiceProvider = this.storageService.accessLevel == AccessLevel.ServiceProvider;

        this.initForm();
        this.getDropdowns();
    }

    private initForm() {
        this.form = this.formBuilder.group(
            {
                companyGroupId: [null],
                companyId: [null],
                dropdownId2: [null],
            },
            { validators: this.checkCompanyOrCompanyGroupFillIn },
        );
    }

    private getDropdowns(isPopulateCompanyGroup: boolean = false) {
        let observable, responseOption: string, responseOption2: string;

        if (this.moduleName == ModuleName['Employee Setup']) {
            observable = this.aggregatorsOptionsService.getEmployeeDropdownOptions(
                this.companyId.value,
                this.companyGroupId.value,
            );
            responseOption = 'companyOptions';

            if (this.storageService.companyId) {
                this.companyId.setValue(this.storageService.companyId);
                this.isCompanyGroupDisabled = true;
            }
        } else if (this.moduleName == ModuleName['Medical Advance']) {
            observable = this.aggregatorsOptionsService.getMedicalAdvanceDropdownOptions(
                this.companyId.value,
                this.companyGroupId.value,
            );
            responseOption = 'companyOptions';
            responseOption2 = 'partnerOptions';
        }

        this.isLoading = true;
        observable
            .pipe(
                finalize(() => {
                    this.isLoading = false;
                }),
            )
            .subscribe(
                (response) => {
                    if (
                        isPopulateCompanyGroup &&
                        this.companyId.value &&
                        response.companyGroupOptions &&
                        response.companyGroupOptions.length == 1
                    ) {
                        this.companyGroupId.setValue(response.companyGroupOptions[0].id);
                    } else {
                        this.companyGroupList = [{ id: null, value: 'None' }].concat(response.companyGroupOptions);
                    }

                    this.companyList = [{ id: null, value: 'None' }].concat(response[responseOption]);

                    if (responseOption2) {
                        this.dropdownList2 = response[responseOption2];
                        this.dropdownId2.setValidators(Validators.required);
                    }

                    if (this.isInitDropdown) {
                        this.processDropdownFieldsAfterDropdownFilter();
                    }
                },
                (err) => {
                    this.errorService.showError(err);
                },
            );
    }

    private processDropdownFieldsAfterDropdownFilter() {
        if (this.isCompanyGroupAdmin) {
            this.isCompanyGroupDisabled = true;
            this.companyGroupId.setValue(this.storageService.companyGroupId);
        } else {
            //disable for company since selected
            if (this.isCompanyAdmin) {
                this.isCompanyDisabled = true;
                this.isCompanyGroupDisabled = true;
            }

            this.companyGroupId.setValue(this.storageService.companyGroupId);
            this.companyId.setValue(this.storageService.companyId);
        }

        this.isInitDropdown = false;
    }

    uploadFile(file: File) {
        if (file == null) return;

        if (!AllowedExcelFileMimeType.some((mime) => mime == file.type)) {
            this.popupService.alert(ExcelFileFormatErrorMessage);
            return;
        }

        if (this.form.invalid) {
            this.showFormError = true;
            this.popupService.alert(RequiredFieldsErrorMessage, RequiredFieldsErrorTitle);
            return;
        }

        this.activeModal.close({
            file,
            companyGroupId: this.companyGroupId.value,
            companyId: this.companyId.value,
            dropdownId2: this.dropdownId2.value,
        });
    }

    downloadTemplate() {
        this.isLoading = true;
        this.UITemplateService.getUploadTemplate(this.moduleName)
            .pipe(
                finalize(() => {
                    this.isLoading = false;
                }),
            )
            .subscribe(
                () => {
                    this.popupService.alert('Downloaded Successfully');
                },
                (err) => {
                    if (!err.body) {
                        this.popupService.alert('No Record Found !');
                    } else {
                        this.errorService.showError(err);
                    }
                },
            );
    }

    private checkCompanyOrCompanyGroupFillIn(group: FormGroup) {
        let error = false;
        const companyId = group.get('companyId').value;
        const companyGroupId = group.get('companyGroupId').value;

        if (!companyId && !companyGroupId) {
            error = true;
        }

        if (error) {
            group.get('companyId').setErrors({ requireOne: true });
            group.get('companyGroupId').setErrors({ requireOne: true });
        } else {
            group.get('companyId').setErrors(null);
            group.get('companyGroupId').setErrors(null);
        }

        return error ? { requireOne: true } : null;
    }

    changeCompanyGroupValue(event) {
        if (this.isServiceProvider) {
            this.companyId.setValue(null);
            this.getDropdowns(true);
        }
    }

    changeCompanyValue(event) {
        if (this.isServiceProvider && this.companyId.value) {
            this.getDropdowns(true);
        }
    }

    cancel(): void {
        this.activeModal.dismiss();
    }

    get companyGroupId() {
        return this.form.get('companyGroupId');
    }
    get companyId() {
        return this.form.get('companyId');
    }
    get dropdownId2() {
        return this.form.get('dropdownId2');
    }
}
