import { Directive, Input, ElementRef, HostListener, AfterViewInit, OnDestroy } from '@angular/core';

@Directive({
    selector: '[aspectRatioComp]',
})
export class ImageAspectRatioDirective implements AfterViewInit, OnDestroy {
    @Input() aspectRatioComp: number;

    observer: MutationObserver;
    interval: any;

    constructor(private el: ElementRef) {}

    ngAfterViewInit() {
        if ('MutationObserver' in window) {
            this.observer = new MutationObserver(this.calculateHeight);

            const config = { attributes: true, childList: true, subtree: true };
            this.observer.observe(this.el.nativeElement, config);
            this.observer.observe(this.el.nativeElement.parentElement, config);
        } else {
            // fallback for <IE10, will notice resize happening
            this.interval = setInterval(() => {
                this.calculateHeight();
            }, 20);
        }
    }

    ngOnDestroy() {
        if (this.observer) this.observer.disconnect();

        if (this.interval) clearInterval(this.interval);
    }

    @HostListener('window:resize') onResize() {
        this.calculateHeight();
    }

    calculateHeight = () => {
        const width = this.el.nativeElement.clientWidth;

        if (width > 0) {
            this.el.nativeElement.style.height = width / this.aspectRatioComp + 'px';
        }
    };
}
