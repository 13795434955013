import { Directive } from '@angular/core';
import { NgbDateAdapter, NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateMomentAdapter } from './ngb-date-moment-adapter';
import { NgbTimeMomentAdapter } from './ngb-time-moment-adapter';

@Directive({
    selector: '[datetimeMomentComp]',
    providers: [
        { provide: NgbDateAdapter, useClass: NgbDateMomentAdapter },
        { provide: NgbTimeAdapter, useClass: NgbTimeMomentAdapter },
    ],
})
export class DatetimeMomentDirective {}
