import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
    selector: '[maxLength]',
})
export class MaxLengthDirective {
    @Input() maxLength: string;

    constructor(
        private el: ElementRef,
        private ren: Renderer2,
    ) {}

    ngOnInit() {
        this.ren.setAttribute(
            this.el.nativeElement,
            'onkeypress',
            'return ( this.value.length < ' + this.maxLength + ' );',
        );
    }
}
