<div class="navbar">
    <a class="navbar-brand ml-lg-3">
        <div class="hariwang-icon-app"></div>
    </a>
    <div class="profile-notification">
        <ul class="navbar-nav align-items-center d-none d-md-flex">
            <li class="nav-item" ngbDropdown placement="bottom-right" (openChange)="isDrop = $event">
                <a class="nav-link pr-0" role="button" ngbDropdownToggle>
                    <div class="d-flex align-items-center">
                        <div class="media-body ml-2 d-none d-lg-block">
                            <div class="mb-0 ft-14 user-name">
                                {{ fullName }}
                            </div>
                        </div>
                        <i class="hariwang-icon-dropdown ml-2" [ngClass]="isDrop ? 'rotate-caret' : ''"></i>
                    </div>
                </a>
                <div class="dropdown-menu-arrow dropdown-menu-right" ngbDropdownMenu>
                    <a class="dropdown-item" (click)="logOut()">
                        <span class="dropdown-text">Log out</span>
                    </a>
                </div>
            </li>
        </ul>
    </div>
</div>
