<div class="side-menu" [class.collapse]="!menuService.isOpen">
    <div class="menu-holder">
        <ng-container *ngFor="let menuGroup of sideMenuService.menuGroups">
            <div
                class="main-menu-panel"
                [ngClass]="activeMenuClass(menu) == 'active-parent' ? 'left-line-active' : 'left-line-inactive'"
                *ngFor="let menu of menuGroup.items">
                <a
                    class="menu-tab"
                    [routerLink]="menu.route"
                    [ngClass]="activeMenuClass(menu)"
                    routerLinkActive="active"
                    #rla="routerLinkActive"
                    (click)="navigate(menu)">
                    <div class="d-flex">
                        <img class="menu-icon" src="{{ menu.icon }}" />
                        <span class="menu-label">{{ menu.name }}</span>
                    </div>
                </a>

                <div *ngIf="!!menu.isExpand">
                    <div class="sub-menu" *ngIf="menu.subModules">
                        <div class="my-3" *ngFor="let subModule of menu.subModules">
                            <a
                                class="sub-menu-tab"
                                [routerLink]="subModule.route"
                                routerLinkActive="active"
                                #rla="routerLinkActive">
                                <span class="menu-label"> {{ subModule.name }}</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
