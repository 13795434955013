import { AfterViewInit, ChangeDetectorRef, Directive, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import tinymce from 'tinymce';

@Directive({
    selector: '[tinyEditor]',
})
export class TinyMCEEditorDirective implements AfterViewInit, ControlValueAccessor {
    private val: any = '';
    private init: boolean = false;

    //selector string: Id of the host element
    @Input() selector: string;

    //This is used to update the model on view update
    @Output() tinyEditorModelChange = new EventEmitter();

    //ChangeDetectorRef is used to update angular component tree whenver a blur event occurs to trigger all the validations
    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private control: NgControl,
    ) {}

    //All the options needed for tinymce editor
    private optionsWithoutImageAndMedia = {
        plugins: [
            'advlist autolink lists link image charmap print preview hr anchor pagebreak',
            'searchreplace wordcount visualblocks visualchars fullscreen code',
            'insertdatetime nonbreaking save table directionality',
            'emoticons template paste textpattern codesample',
            'help',
        ],
        toolbar1:
            'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent',
        toolbar2: 'print link | forecolor backcolor emoticons | codesample',
        menu: {
            file: { title: 'File', items: 'newdocument restoredraft | preview | print ' },
            edit: { title: 'Edit', items: 'undo redo | cut copy paste | selectall | searchreplace' },
            view: {
                title: 'View',
                items: 'code | visualaid visualchars visualblocks | spellchecker | preview fullscreen',
            },
            insert: {
                title: 'Insert',
                items: 'link template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime',
            },
            format: {
                title: 'Format',
                items: 'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | removeformat',
            },
            tools: { title: 'Tools', items: 'spellchecker spellcheckerlanguage | code wordcount' },
            table: { title: 'Table', items: 'inserttable | cell row column | tableprops deletetable' },
            help: { title: 'Help', items: 'help' },
        },
        paste_block_drop: true,
        paste_data_images: false,
        content_style: 'body { -webkit-print-color-adjust: exact; }',
    };

    //registerOnChange, registerOnTouched, writeValue are methods need to be implemented for the interface ControlValueAccessor
    //control value accessor
    onChange: any = () => {};
    onTouch: any = () => {};

    // upon UI element value changes, this method gets triggered
    registerOnChange(fn: any) {
        this.onChange = fn;
    }
    // upon touching the element, this method gets triggered
    registerOnTouched(fn: any) {
        this.onTouch = fn;
    }

    //This method is called whenever model gets updated
    writeValue(value: any): void {
        // This check is necessary because, this method gets called before editor gets initialised.
        // Hence undefined/null pointer exceptions gets thrown
        if (this.init) {
            if (tinymce.get(this.selector)) {
                tinymce.get(this.selector).setContent(value, { format: 'raw' });
            }
        }
    }

    //Update the component tree only when blur event happens. Otherwise following bug will occur.
    //Cursor position changes to 0 or the begining of the editor after every event.
    valueChange() {
        this.valueOnChange(false);
    }

    valueOnChange(change: boolean) {
        if (tinymce.activeEditor) {
            this.val = tinymce.activeEditor.getContent();
        }

        this.control.control.patchValue(this.val);
        if (change) {
            this.changeDetectorRef.detectChanges();
        }
    }

    ngAfterViewInit() {
        let that = this;
        let options = this.optionsWithoutImageAndMedia;

        if (this.selector) {
            options['selector'] = '#' + this.selector;
        } else {
            options['selector'] = '.wysiwyg';
        }

        options['height'] = 500;
        options['schema'] = 'html5';
        options['theme'] = 'silver';
        //write the model value to tinymce editor once gets initialised. And track input and change events
        options['init_instance_callback'] = function (editor) {
            that.writeValue(that.control.value);
            editor.on('change', function (e) {
                that.valueChange();
            });
            editor.on('blur', function (e) {
                that.valueOnChange(true);
            });
            editor.on('keyup', function (e) {
                that.valueChange();
            });
            editor.on('PastePostProcess', function (e) {
                that.valueChange();
            });
        };

        // tinymce.init(options)
        tinymce.init(options).then(() => {
            this.init = true;
        });
    }

    ngOnDestroy() {
        var editor = tinymce.get(this.selector);
        tinymce.remove(editor);
    }
}
