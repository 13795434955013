import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
    selector: '[wholeNumber]',
})
export class WholeNumberDirective {
    constructor(
        private el: ElementRef,
        private ren: Renderer2,
    ) {}

    ngOnInit() {
        this.ren.setAttribute(
            this.el.nativeElement,
            'onkeypress',
            'return event.charCode >= 48 && event.charCode <= 57',
        );
    }
}
