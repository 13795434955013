import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { APIResponseBody } from '../../../shared/models/api-body/api-response-body';
import { FilePath } from '../../enums/file-path.enum';

@Injectable({
    providedIn: 'root',
})
export class UploadService {
    private route = environment.apiServer + 'FileStorages/files';

    constructor(private http: HttpClient) {}

    uploadSingleFile(file: File, filePath: FilePath): Observable<APIResponseBody<string>> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('filePath', filePath.toString());

        return this.http.post<APIResponseBody<string>>(`${this.route}`, formData);
    }

    processBlob(response: HttpResponse<Blob>) {
        let filename = 'DownloadFile.xlsx';
        if (response.headers.get('x-filename')) {
            filename = response.headers.get('x-filename');
        }

        this.promptDownload(response.body, filename);
    }

    promptDownload(blob: Blob, filename: string) {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }
}
