import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { AccountService } from '../../core/services/account.service';
import { StorageService } from '../../core/services/storage.service';

@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        private router: Router,
        private accountService: AccountService,
        private storageService: StorageService,
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            map((event) => {
                return event;
            }),
            catchError((err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401 && err.error.Errors && err.error.Errors.StatusCode == 'Unathorized') {
                        return throwError(err.error);
                    }

                    if (err.status === 401 && !req.url.includes('authentications/refresh')) {
                        return this.handle401Error(req, next);
                    }

                    if (err.error) {
                        return throwError(err.error);
                    }

                    return throwError({
                        errorMessage: 'Application Error. Please report to our engineer.',
                    });
                }

                return throwError({
                    errorMessage: 'Application Error. Please report to our engineer.',
                });
            }),
        );
    }

    private handle401Error(req: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            return this.returnRefreshTokenObservable(next, req);
        } else {
            return this.returnRefreshTokenSubject(next, req);
        }
    }

    returnRefreshTokenObservable(next: HttpHandler, req: HttpRequest<any>) {
        return this.accountService.refreshToken().pipe(
            switchMap((token: any) => {
                this.isRefreshing = false;
                this.refreshTokenSubject.next(token.accessToken);
                return next.handle(this.addAuthenticationToken(req, token.accessToken));
            }),
            catchError((err: any) => {
                this.isRefreshing = false;
                this.storageService.clear();
                this.router.navigate(['login']);
                return throwError(err.error);
            }),
        );
    }

    returnRefreshTokenSubject(next: HttpHandler, req: HttpRequest<any>) {
        return this.refreshTokenSubject.pipe(
            filter((token) => token != null),
            take(1),
            switchMap((token) => {
                return next.handle(this.addAuthenticationToken(req, token));
            }),
        );
    }

    addAuthenticationToken(request: HttpRequest<any>, token: string) {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
            },
        });
    }
}
