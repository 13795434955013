import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment as env } from '../../../environments/environment';
import { APIRequestBody } from '../../shared/models/api-body/api-request-body';
import { APIResponseBody } from '../../shared/models/api-body/api-response-body';
import { LoginProfile } from '../models/account/login-profile';
import { LoginModel } from '../models/account/login.model';
import { ModuleAccess } from '../models/account/module-access';
import { StorageService } from './storage.service';
import { AdminPortalSource } from './constant';

@Injectable({
    providedIn: 'root',
})
export class AccountService {
    route = env.apiServer + 'users/';

    constructor(
        private http: HttpClient,
        private storageService: StorageService,
    ) {}

    login(body: APIRequestBody<LoginModel>): Observable<void> {
        return this.http.post(this.route + 'authentications/login', body).pipe(
            map((response: APIResponseBody<LoginProfile>) => {
                this.storageService.setFromLogin(response.data);
            }),
        );
    }

    logout() {
        return this.http.post(this.route + 'authentications/logout', null).pipe(
            map(() => {
                this.storageService.clear();
            }),
        );
    }

    forgotPassword(body: any): Observable<any> {
        return this.http.post(this.route + 'authentications/forget-passwords', body);
    }

    resetPassword(body: any): Observable<any> {
        return this.http.post(this.route + 'forget-password-reset', body);
    }

    refreshToken(): Observable<LoginProfile> {
        const token = this.storageService.refreshToken;

        if (!token) return throwError({});

        let body = new APIRequestBody(token);

        return this.http.post(this.route + 'authentications/refresh', body).pipe(
            map((response: APIResponseBody<LoginProfile>) => {
                this.storageService.accessToken = response.data.accessToken;
                this.storageService.refreshToken = response.data.refreshToken;
                return response.data;
            }),
        );
    }

    processModulePermissions(): Observable<void> {
        let params: any = {
            source: AdminPortalSource,
        };

        return this.http.get(this.route + 'module-permissions', { params: params }).pipe(
            map((response: APIResponseBody<ModuleAccess[]>) => {
                let accessPermissions: ModuleAccess[] = [];

                if (this.storageService.platformRoleName != 'SuperAdmin') {
                    accessPermissions = response && response.data ? response.data.map((m) => new ModuleAccess(m)) : [];
                }

                this.storageService.accessPermissions = accessPermissions;
            }),
        );
    }
}
