import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AccessService } from './access.service';
import { StorageService } from './storage.service';

@Injectable()
export class Guard implements CanActivate {
    constructor(
        private accessService: AccessService,
        private storageService: StorageService,
        private router: Router,
    ) {}

    canActivate(route: ActivatedRouteSnapshot) {
        let isAuth = this.storageService.accessToken && this.accessService.haveModulePermission(route.data.modules);

        if (!isAuth) this.router.navigate(['login']);

        return isAuth;
    }
}
