import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
    AddressMaxLength,
    RequiredFieldsErrorMessage,
    RequiredFieldsErrorTitle,
} from '../../../../core/constants/validator-constants';
import { PopupService } from '../popup.service';

@Component({
    selector: 'popup-with-remarks',
    templateUrl: './popup-with-remarks.component.html',
    styleUrls: ['./popup-with-remarks.component.scss'],
})
export class PopupWithRemarksComponent implements OnInit {
    form: FormGroup;
    showFormError = false;

    title: string = 'Assign Remarks';
    remarksLabel: string = 'Remarks';
    description: string;
    addressMaxLength = AddressMaxLength;

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
        private popupService: PopupService,
    ) {}

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            remarks: ['', Validators.compose([Validators.required, Validators.maxLength(this.addressMaxLength)])],
        });
    }

    ok(): void {
        if (this.form.invalid) {
            this.showFormError = true;
            this.popupService.alert(RequiredFieldsErrorMessage, RequiredFieldsErrorTitle);
            return;
        }

        this.activeModal.close(this.remarks.value);
    }

    cancel(): void {
        this.activeModal.dismiss();
    }

    get remarks() {
        return this.form.get('remarks');
    }
}
