import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment as env } from '../../../../environments/environment';
import {
    CompanyAggregatorsDropdown,
    EmployeeAggregatorsDropdown,
    EmployeePrivilegesAggregatorsDropdown,
    OperationAdvanceAggregatorsDropdown,
    SalaryAdvanceAggregatorsDropdown,
    UserAggregatorsDropdown,
} from '../../models/aggregators-dropdowns/aggregators-dropdowns';

@Injectable({
    providedIn: 'root',
})
export class AggregatorsOptionsService {
    private route = env.apiServer + 'Aggregators/options';

    constructor(private http: HttpClient) {}

    getCompanyDropdownOptions(): Observable<CompanyAggregatorsDropdown> {
        return this.http
            .get<{ data: CompanyAggregatorsDropdown }>(`${this.route}/companies`)
            .pipe(map((response) => response.data));
    }

    getEmployeeDropdownOptions(companyId: number = null, companyGroupId: number = null): Observable<EmployeeAggregatorsDropdown> {
        let params: any = {};

        if (companyId && companyId != 0 && companyId != 9999) params['companyId'] = companyId;
        if (companyGroupId && companyGroupId != 0 && companyGroupId != 9999) params['companyGroupId'] = companyGroupId;

        return this.http
            .get<{ data: EmployeeAggregatorsDropdown }>(`${this.route}/employees`, { params: params })
            .pipe(map((response) => response.data));
    }

    getEmployeePrivilegesDropdownOptions(isIgnoreAllStatus: boolean): Observable<EmployeePrivilegesAggregatorsDropdown> {
        let params: any = {
            isIgnoreAllStatus
        };
        return this.http
            .get<{ data: EmployeePrivilegesAggregatorsDropdown }>(`${this.route}/employee-privileges`, { params: params })
            .pipe(map((response) => response.data));
    }

    getMedicalAdvanceDropdownOptions(companyId: number = null, companyGroupId: number = null): Observable<OperationAdvanceAggregatorsDropdown> {
        let params: any = {};

        if (companyId && companyId != 0 && companyId != 9999) params['companyId'] = companyId;
        if (companyGroupId && companyGroupId != 0 && companyGroupId != 9999) params['companyGroupId'] = companyGroupId;

        return this.http
            .get<{ data: OperationAdvanceAggregatorsDropdown }>(`${this.route}/medical-advances`, { params: params })
            .pipe(map((response) => response.data));
    }

    getSalaryAdvanceDropdownOptions(companyId: number = null, companyGroupId: number = null): Observable<SalaryAdvanceAggregatorsDropdown> {
        let params: any = {};

        if (companyId && companyId != 0 && companyId != 9999) params['companyId'] = companyId;
        if (companyGroupId && companyGroupId != 0 && companyGroupId != 9999) params['companyGroupId'] = companyGroupId;

        return this.http
            .get<{ data: SalaryAdvanceAggregatorsDropdown }>(`${this.route}/salary-advances`, { params: params })
            .pipe(map((response) => response.data));
    }

    getUserDropdownOptions(companyId: number = null, companyGroupId: number = null): Observable<UserAggregatorsDropdown> {
        let params: any = {};

        if (companyId && companyId != 0 && companyId != 9999) params['companyId'] = companyId;
        if (companyGroupId && companyGroupId != 0 && companyGroupId != 9999) params['companyGroupId'] = companyGroupId;

        return this.http
            .get<{ data: UserAggregatorsDropdown }>(`${this.route}/users`, { params: params })
            .pipe(map((response) => response.data));
    }
}
