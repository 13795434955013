import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'numberSuffix',
})
export class NumberSuffixPipe implements PipeTransform {
    constructor(private currencyPipe: CurrencyPipe) {}

    transform(input: any, args?: any): any {
        let exp;

        if (!args || !args.isSuffix) return this.currencyPipe.transform(input, '', true, '1.0-0');

        const suffixes = ['K', 'M', 'B', 'T', 'P', 'E'];
        const isNegativeValues = input < 0;
        if (
            Number.isNaN(input) ||
            (input < 1000 && input >= 0) ||
            !this.isNumeric(input) ||
            (input < 0 && input > -1000)
        ) {
            if (!!args && this.isNumeric(input) && !(input < 0) && input != 0) {
                return this.currencyPipe.transform(input.toFixed(args), '', true, '1.0-0');
            } else {
                return this.currencyPipe.transform(input, '', true, '1.0-0');
            }
        }

        if (!isNegativeValues) {
            exp = Math.floor(Math.log(input) / Math.log(1000));

            if (exp == 1) return this.currencyPipe.transform(input.toFixed(args), '', true, '1.0-0');

            return (
                this.currencyPipe.transform((input / Math.pow(1000, exp)).toFixed(args), '', true, '1.0-0') +
                suffixes[exp - 1]
            );
        } else {
            input = input * -1;

            if (exp == 1) return this.currencyPipe.transform(input.toFixed(args), '', true, '1.0-0');

            return this.currencyPipe.transform(((input * -1) / Math.pow(1000, exp)).toFixed(args)) + suffixes[exp - 1];
        }
    }

    isNumeric(value): boolean {
        if (value < 0) value = value * -1;
        if (/^-{0,1}\d+$/.test(value)) {
            return true;
        } else if (/^\d+\.\d+$/.test(value)) {
            return true;
        } else {
            return false;
        }
    }

    //https://medium.com/@nimishgoel056/display-number-in-billion-million-thousand-using-custom-pipe-in-angular-b95bf388350a
}
