import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '../../core/services/account.service';
import { StorageService } from '../../core/services/storage.service';
import { BlockUiService } from '../../shared/services/blockUi/block-ui.service';
import { PopupService } from '../../shared/services/popup/popup.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
    profileMenuShow = false;
    fullName = '';
    isDrop = false;

    constructor(
        private router: Router,
        public accountService: AccountService,
        private blockUiService: BlockUiService,
        public storageService: StorageService,
        private popupService: PopupService,
    ) {}

    ngOnInit() {
        this.fullName = this.storageService.fullName;
    }

    logOut() {
        this.popupService
            .confirm('Are you sure you want to logout?', 'Logout?')
            .then(() => {
                this.blockUiService.open();
                this.accountService
                    .logout()
                    .subscribe(() => {
                        this.router.navigate(['/login']);
                    })
                    .add(() => {
                        this.blockUiService.close();
                    });
            })
            .catch(() => {});
    }
}
