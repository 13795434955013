import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { APIResponseBody } from '../../shared/models/api-body/api-response-body';
import { BlockUiService } from '../../shared/services/blockUi/block-ui.service';
import { PopupService } from '../../shared/services/popup/popup.service';

@Injectable({
    providedIn: 'root',
})
export class ErrorService {
    constructor(
        private popupService: PopupService,
        private router: Router,
        private blockUiService: BlockUiService,
    ) {}

    showError(error: APIResponseBody<boolean>, toRoute?: string) {
        this.blockUiService.close();
        let errorMessage = '';

        if (error && error.errors) errorMessage = error.errors[0].message;
        else errorMessage = 'Application Error. Please report to our engineer.';

        this.popupService.alert(errorMessage).then(() => {
            if (toRoute) this.router.navigate([toRoute]);
        });
    }
}
