<div class="modal-header">
    {{ title }}
</div>
<div class="modal-body">
    <form [formGroup]="form" [class.show-form-error]="showFormError">
        <div class="field-panel form-group">
            <div class="field-title field-title-required">
                {{ remarksLabel }}
            </div>
            <div *ngIf="description" class="field-subtitle">
                {{ description }}
            </div>
            <div class="word-limit-holder">
                <textarea
                    [maxlength]="addressMaxLength"
                    placeholder="Maximum {{ addressMaxLength }} characters"
                    rows="3"
                    class="form-control"
                    resize="none"
                    formControlName="remarks"></textarea>
                <div class="word-limit">{{ remarks.value.length }}/{{ addressMaxLength }} characters</div>
            </div>
        </div>
    </form>
    <div class="buttons">
        <button type="button" class="btn btn-cancel" aria-label="Cancel" (click)="cancel()">Cancel</button>
        <button type="button" class="btn btn-primary ok" aria-label="OK" (click)="ok()">Confirm</button>
    </div>
</div>
