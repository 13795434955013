<div class="modal-header">
    <div *ngIf="type == PopupType.alert || type == PopupType.success">
        {{ headerMessage || 'Information' }}
    </div>
    <div *ngIf="type == PopupType.confirm">
        {{ headerMessage || 'Confirmation' }}
    </div>
</div>
<div class="modal-body">
    <div class="message" [innerHTML]="message"></div>

    <div class="buttons">
        <button
            type="button"
            class="btn btn-cancel"
            aria-label="Cancel"
            (click)="cancel()"
            [ngClass]="cancelClass"
            *ngIf="type == PopupType.confirm">
            {{ cancelLabel || 'Cancel' }}
        </button>
        <button type="button" class="btn btn-primary ok" aria-label="OK" (click)="ok()" [ngClass]="okClass">
            {{ type == PopupType.confirm ? okLabel : 'OK' }}
        </button>
    </div>
</div>
