import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NavbarComponent } from './navbar/navbar.component';
import { SideMenuComponent } from './side-menu/side-menu.component';

@NgModule({
    imports: [CommonModule, RouterModule, MatIconModule, NgbModule],
    declarations: [NavbarComponent, SideMenuComponent],
    exports: [NavbarComponent, SideMenuComponent],
})
export class ComponentsModule {}
