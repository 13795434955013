import * as moment from 'moment';

export const WHOLE_NUMBER = /^[0-9]*$/;

export const DefaultMin6CharactersPasswordPattern = /^.{6,}$/;

export const DefaultEmailPattern = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

export const DefaultPasswordPattern =
    '^((?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*., ?/{}=`~()\\]\\[|:;\'"<>\\\\]).{8,})$';

export const DefaultURLPattern =
    /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]*\b([-a-zA-Z0-9()@:%_\+.~#?&\/\/=]*)$/;

export const DefaultEmbedURLPattern =
    /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(\/embed\/)([-a-zA-Z0-9()@:%_\+.~#?&\/\/=]*)$/;

export const YouTubeVideoURL = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;

export const DefaultPhoneNumberPattern = /^(\+?6?01)[0-46-9]([0-9]{7,8})$/;

export const DateLimit = moment(new Date(1700, 1, 1));

export const NameMaxLength = 100;

export const AddressMaxLength = 200;

export const DescriptionMaxLength = 5000;

export const ImageFileSizeLimit = 2048;

export const LogoImageMinimumPixel = 150;

export const EmployeePrivilegeCategoryImageMinimumPixel = 300;

export const LandscapeImageMinimumWidthPixel = 1280;

export const LandscapeImageMinimumHeightPixel = 720;

export const FileSizeLimitErrorMessage = 'Image size must be lesser than 2MB';

export const FileFormatErrorMessage = 'Image type must be .png/.jpeg/.jpg/.webp';

export const ExcelFileFormatErrorMessage = 'Import file type must be .xls/.xlsx';

export const IncorrectUrlErrorMessage: string =
    'Incorrect URL format, please enter valid website url with https:// or http://';

export const IncorrectEmbedUrlErrorMessage: string =
    'Incorrect URL format, please enter valid embed website url with https:// or http://';

export const IncorrectYouTubeUrlErrorMessage: string = 'Incorrect URL format, please enter valid YouTube url';

export const IncorrectPhoneNumberErrorMessage: string = 'Incorrect phone number format, please check';

export const IncorrectEmailErrorMessage: string = 'Incorrect email format, please check';

export const RequiredFieldsErrorTitle: string = 'Required Fields';

export const RequiredFieldsErrorMessage: string = 'Please fill up all required fields!';

export const DefaultErrorMessage: string = 'Unknown error, please report to application engineer.';
