import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MomentModule } from 'ngx-moment';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { LoadingComponent } from './components/loading/loading.component';
import { NoPermissionComponent } from './components/no-permission/no-permission.component';
import { UploaderDirective } from './components/uploader/uploader.directive';
import { ImageAspectRatioDirective } from './directive/image-aspect-ratio.directive';
import { MaxLengthDirective } from './directive/max-length.directive';
import { PositiveNumberWithDecimalDirective } from './directive/positive-number-with-decimal.directive';
import { WholeNumberDirective } from './directive/whole-number.directive';
import { EnumToArrayPipe } from './pipes/enum-to-array.pipe';
import { NumberSuffixPipe } from './pipes/number-suffix.pipe';
import { TrustSrcPipe } from './pipes/trust-src.pipe';
import { DatetimeMomentDirective } from './plugin-configs/ngb-datetime-moment/ngb-datetime-moment.directive';
import { BlockUiModalComponent } from './services/blockUi/block-ui-modal.component';
import { LoaderComponent } from './services/loader/loader.component';
import { PopupImportComponent } from './services/popup/popup-import/popup-import.component';
import { PopupModalComponent } from './services/popup/popup-modal.component';
import { PopupWithRemarksComponent } from './services/popup/popup-with-remarks/popup-with-remarks.component';
import { TinyMCEEditorDirective } from './directive/tiny-mce-editor.directive';

@NgModule({
    declarations: [
        PopupModalComponent,
        PopupImportComponent,
        PopupWithRemarksComponent,
        LoadingComponent,
        BlockUiModalComponent,
        UploaderDirective,
        TrustSrcPipe,
        NumberSuffixPipe,
        BreadcrumbComponent,
        LoaderComponent,
        DatetimeMomentDirective,
        ImageAspectRatioDirective,
        MaxLengthDirective,
        WholeNumberDirective,
        TinyMCEEditorDirective,
        PositiveNumberWithDecimalDirective,
        EnumToArrayPipe,
        NoPermissionComponent,
    ],
    imports: [
        CommonModule,
        NgbModule,
        RouterModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatRadioModule,
        MatDatepickerModule,
        MomentModule,
        NgxSkeletonLoaderModule,
        FormsModule,
        MatMomentDateModule,
        MatIconModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
    ],
    exports: [
        PopupModalComponent,
        PopupImportComponent,
        PopupWithRemarksComponent,
        LoadingComponent,
        BlockUiModalComponent,
        UploaderDirective,
        TrustSrcPipe,
        NumberSuffixPipe,
        BreadcrumbComponent,
        DatetimeMomentDirective,
        LoaderComponent,
        ImageAspectRatioDirective,
        MaxLengthDirective,
        WholeNumberDirective,
        TinyMCEEditorDirective,
        PositiveNumberWithDecimalDirective,
        EnumToArrayPipe,
        NoPermissionComponent,
    ],
    entryComponents: [
        PopupModalComponent,
        BlockUiModalComponent,
        LoaderComponent,
    ],
    providers: [CurrencyPipe, EnumToArrayPipe],
})
export class SharedModule {}
