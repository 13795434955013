import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PopupType } from './popup-type.enum';

@Component({
    selector: 'popup-modal',
    templateUrl: './popup-modal.component.html',
    styleUrls: ['./popup-modal.component.scss'],
})
export class PopupModalComponent {
    PopupType: typeof PopupType = PopupType;
    type: PopupType;
    message: string;
    okLabel: string;
    cancelLabel: string;
    okClass: string;
    cancelClass: string;
    headerMessage: string;

    constructor(public activeModal: NgbActiveModal) {
        this.okLabel = 'Yes';
    }

    ok(): void {
        this.activeModal.close(true);
    }

    cancel(): void {
        this.activeModal.dismiss(false);
    }
}
