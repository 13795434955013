import { Component } from '@angular/core';
// import { NavigationEnd, Router } from '@angular/router';
import { NgbModalConfig, NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
// import { environment } from '../environments/environment';
import { StorageService } from './core/services/storage.service';

declare let gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    constructor(
        private storageService: StorageService,
        private paginationConfig: NgbPaginationConfig,
        private modalConfig: NgbModalConfig, // private router: Router,
    ) {
        // this.router.events.subscribe(event => {
        //     if (event instanceof NavigationEnd) {
        //         const gTagManagerScript = document.createElement('script');
        //         gTagManagerScript.async = true;
        //         gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsId}`;
        //         document.head.appendChild(gTagManagerScript);
        //         const gaScript = document.createElement('script');
        //         gaScript.innerHTML = `
        //                 window.dataLayer = window.dataLayer || [];
        //                 function gtag() { dataLayer.push(arguments); }
        //                 gtag('js', new Date());
        //                 gtag('config', '${environment.googleAnalyticsId}');
        //             `;
        //         document.head.appendChild(gaScript);
        //         gtag('config', environment.googleAnalyticsId,
        //             {
        //                 'page_path': event.urlAfterRedirects
        //             }
        //         );
        //     }
        // });
    }

    title = 'App Title';

    ngOnInit() {
        this.initDevice();
        this.ngbConfig();
    }

    private initDevice() {
        if (!this.storageService.deviceId) {
            this.storageService.deviceId = Math.random().toString(36).substr(2);
        }
    }

    private ngbConfig() {
        this.paginationConfig.ellipses = false;
        this.paginationConfig.boundaryLinks = true;
        this.paginationConfig.rotate = true;
        this.paginationConfig.maxSize = 5;

        this.modalConfig.backdrop = 'static';
        this.modalConfig.keyboard = false;
    }
}
