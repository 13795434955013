import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { environment as env } from '../../../../environments/environment';
import { ModuleName } from '../../enums/module-name.enum';
import { UploadService } from './upload.service';

@Injectable({
    providedIn: 'root',
})
export class UITemplateService {
    private route = env.apiServer + 'Templates';

    constructor(
        private http: HttpClient,
        private uploadService: UploadService,
    ) {}

    getUploadTemplate(moduleName: ModuleName) {
        if (moduleName == ModuleName['Employee Setup']) {
            return this.getEmployeeUploadTemplate();
        } else if (moduleName == ModuleName['Medical Advance']) {
            return this.getMedicalAdvanceTransactionUploadTemplate();
        }
    }

    getEmployeeUploadTemplate() {
        return this.http
            .get<Blob>(this.route + '/employees', {
                observe: 'response',
                responseType: 'blob' as 'json',
            })
            .pipe(tap((response) => this.uploadService.processBlob(response)));
    }

    getMedicalAdvanceTransactionUploadTemplate() {
        return this.http
            .get<Blob>(this.route + '/medical-advances', {
                observe: 'response',
                responseType: 'blob' as 'json',
            })
            .pipe(tap((response) => this.uploadService.processBlob(response)));
    }
}
