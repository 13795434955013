import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root',
})
export class AccessService {
    constructor(private storageService: StorageService) {}

    clearPermissions() {
        this.storageService.accessPermissions = [];
    }

    haveModulePermission(moduleName: string[]) {
        if (this.isAdmins()) return true;
        else if (!this.hasPermissions()) return false;

        return moduleName.some((m) => {
            return this.storageService.accessPermissions.some((r) => {
                return r.moduleName == m && r.isGranted;
            });
        });
    }

    private hasPermissions(): boolean {
        return this.storageService.accessPermissions?.length > 0;
    }

    private isAdmins(): boolean {
        return this.storageService.platformRoleName == 'SuperAdmin';
    }
}
