import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from '../../core/models/menu/menu-item';
import { MenuService } from '../../core/services/menu.service';
import { SideMenuService } from './side-menu.service';

@Component({
    selector: 'app-side-menu',
    templateUrl: './side-menu.component.html',
    styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {
    constructor(
        private router: Router,
        public sideMenuService: SideMenuService,
        public menuService: MenuService,
    ) {}

    ngOnInit() {
        this.sideMenuService.getMenu();
        this.sideMenuService.masterDataMenu[0].isExpand = false;
        this.sideMenuService.operationsMenu[0].isExpand = false;
        this.sideMenuService.reportingMenu[0].isExpand = false;
    }

    navigate(item: MenuItem) {
        this.sideMenuService.masterDataMenu[0].isExpand = this.sideMenuService.masterDataMenu.some((e) => e == item);
        this.sideMenuService.operationsMenu[0].isExpand = this.sideMenuService.operationsMenu.some((e) => e == item);
        this.sideMenuService.reportingMenu[0].isExpand = this.sideMenuService.reportingMenu.some((e) => e == item);

        if (item.subModules) {
            this.router.navigate([item.subModules[0].route]);
        } else {
            this.router.navigate([item.route]);
        }
    }

    activeMenuClass(menu: MenuItem): string {
        if (!this.router.url) return '';

        const path = this.router.url;
        if (
            (menu.subModules && menu.subModules.length > 0 && menu.subModules.some((m) => !!this.activeMenuClass(m))) ||
            path.indexOf(menu.route) == 0
        ) {
            menu.isExpand = true;
            return 'active-parent';
        }

        return '';
    }
}
