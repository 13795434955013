import { Directive, ElementRef, OnInit, Renderer2, Input, Output, EventEmitter } from '@angular/core';

@Directive({
    selector: '[uploaderComp]',
})
export class UploaderDirective implements OnInit {
    @Input() accept = '*';
    @Input() multiple = false;
    @Output() selectFiles: EventEmitter<File[]> = new EventEmitter<File[]>();

    fileInput: any;

    constructor(
        private element: ElementRef,
        private renderer: Renderer2,
    ) {}

    ngOnInit(): void {
        this.appendFileInput();
    }

    appendFileInput(): void {
        this.fileInput = this.renderer.createElement('input');
        this.renderer.setAttribute(this.fileInput, 'type', 'file');
        this.renderer.setStyle(this.fileInput, 'display', 'none');
        this.renderer.appendChild(this.element.nativeElement, this.fileInput);

        if (this.multiple) {
            this.renderer.setAttribute(this.fileInput, 'multiple', 'true');
        }

        if (this.accept) {
            this.renderer.setAttribute(this.fileInput, 'accept', this.accept);
        }

        this.renderer.listen(this.element.nativeElement, 'click', () => {
            this.fileInput.click();
        });

        this.renderer.listen(this.fileInput, 'change', this.onSelectFiles.bind(this));
    }

    onSelectFiles(event: any) {
        if (!event.target.files) {
            return;
        }

        if (this.multiple) {
            this.selectFiles.emit(Array.from(event.target.files));
        } else {
            this.selectFiles.emit(event.target.files[0]);
        }

        this.fileInput.value = '';
    }
}
