import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ModuleName } from '../../core/enums/module-name.enum';
import { MenuGroup } from '../../core/models/menu/menu-group';
import { MenuItem } from '../../core/models/menu/menu-item';
import { AccessService } from '../../core/services/access.service';
import { StorageService } from '../../core/services/storage.service';

@Injectable({
    providedIn: 'root',
})
export class SideMenuService {
    masterDataMenu: MenuItem[];
    operationsMenu: MenuItem[];
    reportingMenu: MenuItem[];
    userManagementMenu: MenuItem[];
    modulePermissionMenu: MenuItem[];
    menuGroups: MenuGroup[];

    constructor(
        private router: Router,
        private accessService: AccessService,
        private storageService: StorageService,
    ) {}

    getMenu() {
        this.masterDataMenu = [
            {
                name: 'Master Data',
                route: '/master-data',
                icon: '../../../assets/img/side-menu-icons/master_data_icon.png',
                moduleName: [],
                isExpand: false,
                subModules: [
                    {
                        name: 'Company Info',
                        route: '/company-info',
                        moduleName: [ModuleName['Company Setup']],
                    },
                    {
                        name: 'Company Group',
                        route: '/company-group',
                        moduleName: [ModuleName['Company Group']],
                    },
                    {
                        name: 'Employee Info',
                        route: '/employee-info',
                        moduleName: [ModuleName['Employee Setup']],
                    },
                    {
                        name: 'Employee Privilege Category',
                        route: '/employee-privilege-category',
                        moduleName: [ModuleName['Employee Privillege Category']],
                    },
                ],
            },
        ];

        this.operationsMenu = [
            {
                name: 'Operations',
                route: '/operations',
                icon: '../../../assets/img/side-menu-icons/operations_icon.png',
                moduleName: [],
                isExpand: false,
                subModules: [
                    {
                        name: 'Medical Advance',
                        route: '/medical-advance',
                        moduleName: [ModuleName['Medical Advance']],
                    },
                    {
                        name: 'Salary Advance',
                        route: '/salary-advance',
                        moduleName: [ModuleName['Salary Advance']],
                    },
                    {
                        name: 'Employee Privilege',
                        route: '/operations-employee-privilege',
                        moduleName: [ModuleName['Employee Privillege']],
                    },
                ],
            },
        ];

        this.reportingMenu = [
            {
                name: 'Reporting',
                route: '/reporting',
                icon: '../../../assets/img/side-menu-icons/reporting-icon.png',
                moduleName: [],
                isExpand: false,
                subModules: [
                    {
                        name: 'Medical Advance',
                        route: '/reporting-medical-advance',
                        moduleName: [ModuleName['Medical Advance Report']],
                    },
                    {
                        name: 'Salary Advance',
                        route: '/reporting-salary-advance',
                        moduleName: [ModuleName['Salary Advance Report']],
                    },
                    {
                        name: 'Bank Submission',
                        route: '/reporting-bank-submission-salary-advance',
                        moduleName: [ModuleName['Salary Advance Bank Submission']],
                    },
                ],
            },
        ];

        this.modulePermissionMenu = [
            {
                name: 'Module Permission',
                route: '/module-permission',
                icon: '../../../assets/img/side-menu-icons/access-permission-icon.png',
                moduleName: [ModuleName['Role Module Permission']],
            },
        ];

        this.userManagementMenu = [
            {
                name: 'User Info',
                route: '/user-info',
                icon: '../../../assets/img/side-menu-icons/user_management_icon.png',
                moduleName: [ModuleName['User']],
            },
        ];

        this.menuGroups = [
            { name: 'Master Data', items: this.masterDataMenu },
            { name: 'Operations', items: this.operationsMenu },
            { name: 'Reporting', items: this.reportingMenu },
            { name: 'Module Permission', items: this.modulePermissionMenu },
            { name: 'User Info', items: this.userManagementMenu },
        ];

        if (this.storageService.accessToken) this.removeNoAccessMenus();
    }

    removeNoAccessMenus() {
        if (this.storageService.platformRoleName == 'SuperAdmin') return;

        this.menuGroups = JSON.parse(JSON.stringify(this.menuGroups));

        this.menuGroups.forEach((group) => {
            group.items.forEach((menu) => {
                if (menu.subModules) {
                    menu.subModules = menu.subModules.filter((subModules) => {
                        return (
                            subModules.moduleName == undefined ||
                            this.accessService.haveModulePermission(subModules.moduleName)
                        );
                    });
                }
            });

            group.items = group.items.filter((menu) => {
                if (menu.subModules) return menu.subModules.length > 0;
                else return menu.moduleName == undefined || this.accessService.haveModulePermission(menu.moduleName);
            });
        });

        this.menuGroups = this.menuGroups.filter((group) => {
            return group.items.length > 0;
        });
    }

    navigateToFirstMenu(): boolean {
        if (this.menuGroups.length == 0) {
            return false;
        }

        if (this.menuGroups[0].items[0].subModules) {
            let employeeInfoMenu = this.menuGroups[0].items[0].subModules.find(
                (module) => module.route == '/employee-info',
            );
            this.router.navigate([
                employeeInfoMenu ? employeeInfoMenu.route : this.menuGroups[0].items[0].subModules[0].route,
            ]);
        } else this.router.navigate([this.menuGroups[0].items[0].route]);

        return true;
    }
}
