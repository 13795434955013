export enum ModuleName {
    'Employee Setup' = 'Employee Setup',
    'Company Setup' = 'Company Setup',
    'Company Group' = 'Company Group',
    'Salary Advance' = 'Salary Advance',
    'Medical Advance' = 'Medical Advance',
    'Role Module Permission' = 'Role Module Permission',
    'User' = 'User',
    'Employee Privillege Category' = 'Employee Privillege Category',
    'Employee Privillege' = 'Employee Privillege',
    'Medical Advance Report' = 'Medical Advance Report',
    'Salary Advance Report' = 'Salary Advance Report',
    'Salary Advance Bank Submission' = 'Salary Advance Bank Submission',
}
