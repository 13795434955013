<div class="modal-header">
    {{ title }}
</div>
<div class="modal-body">
    <ng-container *ngIf="!isLoading">
        <form [formGroup]="form" [class.show-form-error]="showFormError">
            <div class="field-panel form-group" *ngIf="companyGroupFieldTitle">
                <div class="field-title">
                    {{ companyGroupFieldTitle }}
                </div>
                <div class="field-holder mb-3">
                    <mat-form-field class="form-control w-100" floatLabel="never">
                        <mat-label>- Select from the list -</mat-label>
                        <mat-select
                            disableOptionCentering
                            panelClass="app-dropdown-panel"
                            formControlName="companyGroupId"
                            [disabled]="isCompanyGroupDisabled"
                            (selectionChange)="changeCompanyGroupValue()">
                            <mat-option *ngFor="let option of companyGroupList" [value]="option.id">
                                {{ option.value }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="field-panel form-group">
                <div class="field-title">
                    {{ fieldTitle }}
                </div>
                <div class="field-holder mb-3">
                    <mat-form-field class="form-control w-100" floatLabel="never">
                        <mat-label>- Select from the list -</mat-label>
                        <mat-select
                            disableOptionCentering
                            panelClass="app-dropdown-panel"
                            formControlName="companyId"
                            [disabled]="isCompanyDisabled"
                            (selectionChange)="changeCompanyValue()">
                            <mat-option *ngFor="let option of companyList" [value]="option.id">
                                {{ option.value }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="field-panel form-group" *ngIf="fieldTitle2">
                <div class="field-title field-title-required">
                    {{ fieldTitle2 }}
                </div>
                <div class="field-holder mb-3">
                    <mat-form-field class="form-control w-100" floatLabel="never">
                        <mat-label>- Select from the list -</mat-label>
                        <mat-select
                            disableOptionCentering
                            required
                            panelClass="app-dropdown-panel"
                            formControlName="dropdownId2">
                            <mat-option *ngFor="let option of dropdownList2" [value]="option.id">
                                {{ option.value }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="field-subtitle">
                Please click
                <a (click)="downloadTemplate()" class="font-weight-bold">[here]</a>
                to download employee info import template.
            </div>
        </form>
        <div class="buttons">
            <button type="button" class="btn btn-cancel" aria-label="Cancel" (click)="cancel()">Cancel</button>
            <button
                class="btn btn-primary ok"
                uploaderComp
                (selectFiles)="uploadFile($event)"
                accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
                Upload
            </button>
        </div>
    </ng-container>
    <loading-comp *ngIf="isLoading"></loading-comp>
</div>
