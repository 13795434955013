import { Injectable } from '@angular/core';
import { NgbTimeAdapter, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { Moment } from 'moment';
import * as moment from 'moment';

@Injectable()
export class NgbTimeMomentAdapter extends NgbTimeAdapter<Moment> {
    private now = moment().hour(0).minute(0).second(0);
    private year = this.now.year();
    private month = this.now.month() + 1;
    private day = this.now.date();

    fromModel(momentDate: Moment): NgbTimeStruct {
        if (!momentDate || !momentDate.isValid()) {
            return null;
        }

        this.year = momentDate.year();
        this.month = momentDate.month() + 1;
        this.day = momentDate.date();

        return {
            hour: momentDate.hour(),
            minute: momentDate.minute(),
            second: momentDate.second(),
        };
    }

    toModel(ngbTime: NgbTimeStruct): Moment {
        if (!ngbTime) {
            return null;
        }

        return moment(
            `${this.year}-${this.month}-${this.day} ${ngbTime.hour}:${ngbTime.minute}:${ngbTime.second}`,
            'YYYY-M-D H:m:s',
        );
    }
}
