import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'loader-comp',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
    public displayLoader = false;

    constructor() {}

    ngOnInit() {}
}
