import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[positiveNumberWithDecimal]',
})
export class PositiveNumberWithDecimalDirective {
    @HostListener('keydown', ['$event'])
    onKeyDown(event) {
        let e = <KeyboardEvent>event;
        let preventCodes = ['KeyE', 'Minus', 'Equal', 'NumpadAdd', 'NumpadSubtract', 'ArrowDown', 'ArrowUp'];
        if (preventCodes.includes(e.code)) e.preventDefault();
        else return;
    }
}
