export const LISTING_FILTER_DATE_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'DD MMM YYYY',
        monthYearLabel: 'YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY',
    },
};

export const LOCALE: string = 'MY';

export const ActiveStatusEnum: number = 1;

export const AllowedImageFileMimeType: string[] = ['image/png', 'image/jpeg', 'image/webp'];

export const AllowedExcelFileMimeType: string[] = [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const ImagePlaceholderProfile = '../../../../assets/img/icons/img-placeholder-profile@2x.png';

export const ImagePlaceholderRectangle = '../../../../assets/img/icons/img-placeholder-rectangle@2x.png';

export const HariwangWebsiteLink = 'https://www.hariwang.com.my/';

export const HariwangWhiteLoginLogo = '../../../assets/img/brand/hariwang_white_login_logo.png';

export const AdminPortalSource = 2;
