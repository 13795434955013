import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'enumToArray',
})
export class EnumToArrayPipe implements PipeTransform {
    transform(data: Object, isString?: boolean) {
        let keys = [];

        for (var enumMember in data) {
            if (data[enumMember] && typeof data[enumMember] === 'string') {
                data[enumMember] = data[enumMember].charAt(0).toUpperCase() + data[enumMember].slice(1);
            }

            if (!isNaN(parseInt(enumMember, 10))) {
                keys.push({
                    key: parseInt(enumMember),
                    id: parseInt(enumMember),
                    value: data[enumMember],
                });
            } else if (isString) {
                keys.push({
                    key: enumMember,
                    id: enumMember,
                    value: data[enumMember],
                });
            }
        }

        return keys;
    }
}
