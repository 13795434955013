import { ModuleAccessModel } from './module-access-model';

export class ModuleAccess {
    moduleId: number;
    moduleName: string;
    isGranted: boolean;

    constructor(model: ModuleAccessModel) {
        this.moduleId = model.moduleId;
        this.moduleName = model.moduleName;
        this.isGranted = model.isGranted;
    }
}
