import { Injectable } from '@angular/core';
import { LoginProfile } from '../models/account/login-profile';
import { ModuleAccess } from '../models/account/module-access';

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    get accessLevel(): number {
        return parseInt(localStorage.getItem('accessLevel') || null);
    }

    set accessLevel(accessLevel: number) {
        localStorage.setItem('accessLevel', accessLevel ? accessLevel.toString() : '');
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') || '';
    }

    set accessToken(accessToken: string) {
        localStorage.setItem('accessToken', accessToken || '');
    }

    get refreshToken(): string {
        return localStorage.getItem('refreshToken') || '';
    }

    set refreshToken(refreshToken: string) {
        localStorage.setItem('refreshToken', refreshToken || '');
    }

    get userId(): string {
        return localStorage.getItem('userId') || '';
    }

    set userId(userId: string) {
        localStorage.setItem('userId', userId || '');
    }

    get companyId(): number {
        return parseInt(localStorage.getItem('companyId') || null);
    }

    set companyId(companyId: number) {
        localStorage.setItem('companyId', companyId ? companyId.toString() : '');
    }

    get companyGroupId(): number {
        return parseInt(localStorage.getItem('companyGroupId') || null);
    }

    set companyGroupId(companyGroupId: number) {
        localStorage.setItem('companyGroupId', companyGroupId ? companyGroupId.toString() : '');
    }

    get accessPermissions(): ModuleAccess[] {
        return localStorage.getItem('accessPermissions') ? JSON.parse(localStorage.getItem('accessPermissions')) : '';
    }

    set accessPermissions(accessPermissions: ModuleAccess[]) {
        localStorage.setItem('accessPermissions', JSON.stringify(accessPermissions) || '');
    }

    get platformRoleId(): number {
        return parseInt(localStorage.getItem('platformRoleId') || null);
    }

    set platformRoleId(platformRoleId: number) {
        localStorage.setItem('platformRoleId', platformRoleId ? platformRoleId.toString() : '');
    }

    get platformRoleName(): string {
        return JSON.parse(localStorage.getItem('platformRoleName')) || '';
    }

    set platformRoleName(platformRoleName: string) {
        localStorage.setItem('platformRoleName', JSON.stringify(platformRoleName) || '');
    }

    get deviceId(): string {
        return localStorage.getItem('deviceId') || '';
    }

    set deviceId(deviceId: string) {
        localStorage.setItem('deviceId', deviceId || '');
    }

    get isRememberMe(): boolean {
        return localStorage.getItem('isRememberMe') == 'true';
    }

    set isRememberMe(isRememberMe: boolean) {
        localStorage.setItem('isRememberMe', isRememberMe ? isRememberMe.toString() : '');
    }

    get email(): string {
        return localStorage.getItem('email') || null;
    }

    set email(email: string) {
        localStorage.setItem('email', email || '');
    }

    get userName(): string {
        return localStorage.getItem('userName') || null;
    }

    set userName(userName: string) {
        localStorage.setItem('userName', userName || '');
    }

    get fullName(): string {
        return localStorage.getItem('fullName') || null;
    }

    set fullName(fullName: string) {
        localStorage.setItem('fullName', fullName || '');
    }

    setFromLogin(input: LoginProfile): void {
        this.accessLevel = input.accessLevel;
        this.accessToken = input.accessToken;
        this.refreshToken = input.refreshToken;
        this.userName = input.userName;
        this.platformRoleId = input.platformRoleId;
        this.platformRoleName = input.platformRoleName;
        this.userId = input.id;
        this.email = input.email;
        this.fullName = input.fullName;
        this.companyId = input.companyId;
        this.companyGroupId = input.companyGroupId;
    }

    clear(): void {
        let attrs = Object.getOwnPropertyDescriptors(StorageService.prototype);

        for (let attr in attrs) {
            if (attrs[attr].set) {
                if (attr == 'email' && !this.isRememberMe) {
                    this[attr] = null;
                } else if (attr !== 'deviceId') {
                    this[attr] = null;
                }
            }
        }
    }
}
